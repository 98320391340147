import ChartDataLabels from "chartjs-plugin-datalabels"
import { HorizontalBar, mixins } from "vue-chartjs"

export default {
  extends: HorizontalBar,
  mixins: [mixins.reactiveProp],
  props: {
    chartOptions: {
      type: Object,
      required: true
    },
    clickToFilter: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    showChart (data) {
      if (data) {
        try {
          this.renderChart(data, this.chartOptions)
        } catch (e) {
          console.log(e)
        }
      }
    }
  },
  mounted () {
    this.addPlugin(ChartDataLabels)
    this.showChart(this.chartData)
  },
  watch: {
    chartData (val) {
      this.showChart(val)
    }
  }
}
